<template>
<div id="pageTable">
    <v-container grid-list-xl fluid class="page-header-right">
        <v-layout row wrap>
           <!-- Summary info -->
            <v-flex lg6 pa-0 pl-3 pt-3>
                <v-btn
                    :color="payservice.Status | display_color(orderStatus)"
                    width="160px"
                    height="36px"
                    small
                    dark
                    depressed
                    outlined
                  >{{ payservice.Status | display_value(orderStatus)}}</v-btn>
            </v-flex>
            <!-- Action buttons -->
            <v-flex lg6 pa-0 pr-6 pt-6>
                <v-layout row wrap justify-end>
                    <v-btn v-if="payservice.Status != orderStatusEnum.hoan_thanh && payservice.Status != orderStatusEnum.don_huy" @click="saveData" color="#a77800" dark>
                        Lưu lại
                    </v-btn>
                    <v-btn v-if="payservice.Status == orderStatusEnum.cho_bao_gia" @click="waitPayment" color="#646262" dark>
                        Chờ thanh toán
                    </v-btn>
                    <v-btn v-if="payservice.Status == orderStatusEnum.da_thanh_toan" @click="completePayment" color="#646262" dark>
                        Hoàn thành
                    </v-btn>
                    <v-btn v-if="payservice.Status != orderStatusEnum.hoan_thanh && adminRole" @click="cancelOrder" dark>
                        {{ payservice.Status != orderStatusEnum.don_huy ? 'Huỷ' : 'Đặt lại' }} 
                    </v-btn>
                </v-layout>
            </v-flex>
            <v-flex lg6>
                <v-widget>
                    <div slot="widget-header-action">
                        <h4 class="pl-2 pr-3 font-weight-bold">{{payservice.CreatedDate | display_date('DD-MM-YYYY HH:mm:ss') | capitalize}}</h4>
                    </div>
                    <div slot="widget-content" class="customer-info-box">
                        <v-card-text>
                            <v-layout row wrap pt-1>
                                <v-flex lg4 sm4>
                                    <v-card-title class="pa-0">Khách hàng</v-card-title>
                                </v-flex>
                                <v-flex lg8 sm8 pa-0>
                                    <div class="mt-4 account-toggle font-weight-bold text-red" @click="showCustomerDetail = !showCustomerDetail">{{customer.Username}} <span class="icon" :class="{'open':showCustomerDetail}"><i aria-hidden="true" class="v-icon notranslate mdi mdi-chevron-down theme--light"></i></span></div>
                                </v-flex>
                            </v-layout>
                            <v-card class="mx-auto" outlined v-if="customerView && showCustomerDetail">
                                <v-list-item three-line>
                                <v-list-item-content>
                                    <v-list-item-title class="mb-2"><a href="javascript:void(0)" class="font-weight-bold text-red" @click="gotoCustomerDetail(customer.ID)">{{customer.Username}}</a></v-list-item-title>
                                    <v-list-item-subtitle class="mb-1">Họ tên: {{customer.FirstName + ' ' + customer.LastName}}</v-list-item-subtitle>
                                    <v-list-item-subtitle class="mb-1">Email: {{customer.Email}}</v-list-item-subtitle>
                                    <v-list-item-subtitle class="mb-1">Điện thoại: {{`${customer.PhoneNumber}`}}</v-list-item-subtitle>
                                    <v-list-item-subtitle class="mb-1">Địa chỉ: {{customer.Address}}</v-list-item-subtitle>
                                </v-list-item-content>
                                </v-list-item>
                            </v-card>
                            <v-layout row wrap pt-2>
                                <v-flex lg12 sm12 :style="(!customerView || !showCustomerDetail) ? 'height: 300px' : 'height: 163px'">
                                </v-flex>
                            </v-layout>
                            <v-layout row wrap pt-2>
                                <v-flex lg4 md4>
                                    <v-card-title class="pa-0 font-weight-bold text-red">Trạng thái</v-card-title>
                                </v-flex>
                                <v-flex lg8 sm8 pa-0>
                                    <v-select class="pa-0 font-weight-bold text-red" :items="orderStatus" v-model="payservice.Status" item-text="name" item-value="value" disabled></v-select>
                                </v-flex>
                            </v-layout>
                        </v-card-text>
                    </div>
                </v-widget>
            </v-flex>
            <!-- Danh gia don hang -->
            <v-flex lg6>
                <v-widget title="Chat box">
                    <div slot="widget-content">
                        <message-box :orderId="modifyId" :orderType="4"></message-box>
                    </div>
                </v-widget>
            </v-flex>
            <!-- Chi phi don hang -->
            <v-flex md12 lg12 sm12>
                <v-widget title="Chi tiết thanh toán hộ">
                    <div slot="widget-content">
                        <v-layout row wrap>
                            <v-flex lg11 sm12 pa-0 ma-0 pt-4>
                                <v-layout class="font-weight-bold" row wrap>
                                    <v-flex lg3 sm3>
                                        <v-card-text>Tỷ giá</v-card-text>
                                    </v-flex>
                                    <v-flex lg9 sm9>
                                        <v-layout row wrap>
                                            <v-flex lg6 sm6 pa-0 pl-2 pr-2>
                                                <h-currency-input v-model="payservice.ExchangeRate" :decimal="0" label="₫"></h-currency-input>
                                            </v-flex>
                                        </v-layout>
                                    </v-flex>
                                </v-layout>
                                <v-layout class="font-weight-bold" row wrap>
                                    <v-flex lg3 sm3>
                                        <v-card-text>Số tiền thanh toán hộ</v-card-text>
                                    </v-flex>
                                    <v-flex lg9 sm9>
                                        <v-layout row wrap>
                                            <v-flex lg6 sm6 pa-0 pl-2 pr-2>
                                                <h-currency-input v-model="payservice.Amount" :decimal="1" label="¥"></h-currency-input>
                                            </v-flex>
                                            <v-flex lg6 sm6 pa-0 pl-2 pr-2>
                                                <h-currency-input v-model="payservice.AmountVND" :decimal="0" label="₫" disabled></h-currency-input>
                                            </v-flex>
                                        </v-layout>
                                    </v-flex>
                                </v-layout>
                                <v-layout class="font-weight-bold" row wrap>
                                    <v-flex lg3 sm3>
                                        <v-card-text>Phí thanh toán (¥)</v-card-text>
                                    </v-flex>
                                    <v-flex lg9 sm9>
                                        <v-layout row wrap>
                                            <v-flex lg6 sm6 pa-0 pl-2 pr-2>
                                                <h-currency-input v-model="payservice.Fee" :decimal="1" label="¥"></h-currency-input>
                                            </v-flex>
                                            <v-flex lg6 sm6 pa-0 pl-2 pr-2>
                                                <h-currency-input v-model="payservice.FeeAmount" :decimal="0" label="đ" disabled></h-currency-input>
                                            </v-flex>
                                        </v-layout>
                                    </v-flex>
                                </v-layout>
                                <v-layout class="font-weight-bold" row wrap v-if="payservice.PayType == payTypeEnum.chuyen_khoan_ho">
                                    <v-flex lg3 sm3>
                                        <v-card-text>Phí bảo hiểm hàng hoá (%)</v-card-text>
                                    </v-flex>
                                    <v-flex lg9 sm9>
                                        <v-layout row wrap>
                                            <v-flex lg6 sm6 pa-0 pl-2 pr-2>
                                                <h-currency-input v-model="payservice.GuaranteeRate" :decimal="1" label="%"></h-currency-input>
                                            </v-flex>
                                            <v-flex lg6 sm6 pa-0 pl-2 pr-2>
                                                <h-currency-input v-model="payservice.GuaranteeAmount" :decimal="0" label="đ" disabled></h-currency-input>
                                            </v-flex>
                                        </v-layout>
                                    </v-flex>
                                </v-layout>
                                <v-layout class="font-weight-bold" row wrap>
                                    <v-flex lg3 sm3>
                                        <v-card-text>Tổng tiền phải trả</v-card-text>
                                    </v-flex>
                                    <v-flex lg9 sm9>
                                        <h-currency-input v-model="payservice.TotalAmount" :decimal="0" label="đ" disabled></h-currency-input>
                                    </v-flex>
                                </v-layout>
                                <template v-if="payservice.PayType == payTypeEnum.chuyen_khoan_ho">
                                    <v-layout class="font-weight-bold" row wrap>
                                        <v-flex lg3 sm3>
                                            <v-card-text>Ngân hàng nhận</v-card-text>
                                        </v-flex>
                                        <v-flex lg9 sm9>
                                            <v-text-field v-model="payservice.BankName" label="Ngân hàng người nhận"></v-text-field>
                                        </v-flex>
                                    </v-layout>
                                    <v-layout class="font-weight-bold" row wrap>
                                        <v-flex lg3 sm3>
                                            <v-card-text>Người nhận</v-card-text>
                                        </v-flex>
                                        <v-flex lg9 sm9>
                                            <v-text-field v-model="payservice.BankAccountName" label="Họ tên người nhận"></v-text-field>
                                        </v-flex>
                                    </v-layout>
                                    <v-layout class="font-weight-bold" row wrap>
                                        <v-flex lg3 sm3>
                                            <v-card-text>Tài khoản</v-card-text>
                                        </v-flex>
                                        <v-flex lg9 sm9>
                                            <v-text-field v-model="payservice.BankAccountNumber" label="Tài khoản người nhận"></v-text-field>
                                        </v-flex>
                                    </v-layout>
                                    <v-layout class="font-weight-bold" row wrap>
                                        <v-flex lg3 sm3>
                                            <v-card-text>Nội dung thanh toán</v-card-text>
                                        </v-flex>
                                        <v-flex lg9 sm9>
                                            <v-text-field v-model="payservice.Content" label="Nội dung thanh toán"></v-text-field>
                                        </v-flex>
                                    </v-layout>
                                </template>
                                <template v-else>
                                    <v-layout class="font-weight-bold" row wrap>
                                        <v-flex lg3 sm3>
                                            <v-card-text>Tài khoản thanh toán hộ Alipay</v-card-text>
                                        </v-flex>
                                        <v-flex lg9 sm9>
                                            <v-text-field v-model="payservice.AlipayAuthorize" label="Tài khoản thanh toán hộ Alipay"></v-text-field>
                                        </v-flex>
                                    </v-layout>
                                </template>
                                <v-layout class="font-weight-bold" row wrap>
                                    <v-flex lg3 sm3>
                                        <v-card-text>Hình ảnh</v-card-text>
                                    </v-flex>
                                    <v-flex lg6 sm6>
                                        <a :href="payservice.ScreenCapture" target="_blank">
                                            <img :src="payservice.ScreenCapture" height="150" v-if="payservice.ScreenCapture">
                                        </a>
                                        <v-text-field
                                            label="Chọn ảnh từ máy tính"
                                            @click="pickFile"
                                            v-model="imageName"
                                            prepend-icon="attach_file"
                                        ></v-text-field>
                                        <input
                                            type="file"
                                            style="display: none"
                                            ref="image"
                                            accept="image/*"
                                            @change="onFilePicked"
                                        >
                                    </v-flex>
                                    <v-flex lg3 sm3>
                                        <v-btn color="primary" @click="upload">Tải ảnh</v-btn>
                                    </v-flex>
                                </v-layout>
                            </v-flex>
                        </v-layout>
                    </div>
                </v-widget>
            </v-flex>
        </v-layout>
    </v-container>
    <v-overlay :value="appLoading" z-index="999999">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
</div>
</template>

<script>
import { mapState } from "vuex";
import VWidget from "@/components/VWidget";
import MessageBox from "@/components/chat/MessageBox";
import { sendErrorNotice } from "@/commons/utils";
import configs from "@/configs";
import firebase from 'firebase';
export default {
    components: {
        VWidget,
        MessageBox
    },
    data() {
        return {
            showCustomerDetail: false,
            orderStatus: configs.PAY_SERVICE_STATUS,
            orderStatusEnum: configs.PAY_SERVICE_STATUS_ENUM,
            payTypeEnum: configs.PAY_SERVICE_TYPE_ENUM,
            imageName: "",
            imageUrl: "",
            imageFile: "",
            appLoading: false
        }
    },
    computed: {
        ...mapState({
            loading: state => state.payservice.loading,
            payservice: state => state.payservice.selected,
            customer: state => state.customer.selected
        }),
        modifyId() {
            return Number(this.$route.params.Pid);
        },
        customerView() {
            return ((this.$store.state.authentication.user.roletype & configs.ROLE_TYPE.Admin) == configs.ROLE_TYPE.Admin) || ((this.$store.state.authentication.user.roletype & configs.ROLE_TYPE.Manager) == configs.ROLE_TYPE.Manager);
        },
        adminRole() {
            return (this.$store.state.authentication.user.roletype & configs.ROLE_TYPE.Admin) == configs.ROLE_TYPE.Admin;
        }
    },
    watch: {
        modifyId() {
            this.refreshData();
        },
        payservice() {
            if(this.payservice) {
                this.$store.dispatch("customer/getCustomerDetail", this.payservice.UID);
            }
        }
    },
    methods: {
        pickFile() {
            this.$refs.image.click();
        },
        onFilePicked(e) {
            const files = e.target.files;
            if (files[0] !== undefined) {
                this.imageName = files[0].name;
                if (this.imageName.lastIndexOf(".") <= 0) {
                return;
                }
                const fr = new FileReader();
                fr.readAsDataURL(files[0]);
                fr.addEventListener("load", () => {
                this.imageUrl = fr.result;
                //console.log("imageUrl");
                this.imageFile = files[0]; // this is an image file that can be sent to server...
                //this.getImages();
                });
            } else {
                this.imageName = "";
                this.imageFile = "";
                this.imageUrl = "";
            }
        },
        // 画像アップロード処理
        upload: function() {
            // ストレージオブジェクト作成
            var storageRef = firebase.storage().ref();
            // ファイルのパスを設定
            var mountainsRef = storageRef.child(`images/${this.imageName}`);
            // ファイルを適用してファイルアップロード開始
            this.appLoading = true;
            mountainsRef.put(this.imageFile).then(snapshot => {
                snapshot.ref.getDownloadURL().then(downloadURL => {
                this.imageUrl = downloadURL;
                const bucketName = configs.SYSTEM_CONFIG.bucketName;
                const filePath = this.imageName;
                this.payservice.ScreenCapture =  `https://firebasestorage.googleapis.com/v0/b/${bucketName}/o/images` + "%2F" + `${encodeURIComponent(filePath)}?alt=media`
                this.appLoading = false;
                });
            }, error => {
                this.appLoading = false;
                sendErrorNotice('Có lỗi upload ảnh (lưu ý ảnh tối đa 3Mb)')
            });
        },
        refreshData() {
            if (this.modifyId > 0) {
                this.$store.dispatch("payservice/getDetail", this.modifyId);
            }
            else {
                this.$store.commit("payservice/setDetail", { data: {
                        ID: 0,
                        UID: 0,
                        Username: '',
                        Status: 2,
                        Amount: 0
                    }
                })
            }
        },
        saveData() {
            this.$store.dispatch("payservice/setDetail", {
                data: this.payservice,
                id: this.payservice.ID
            });
            if(this.payservice.ID == 0) {
                window.getApp.$emit("APP_NAVIGATE", "PayServiceList");
            }
        },
        waitPayment() {
            this.$store.dispatch("payservice/setDetail", {
                data: {
                    Status: this.orderStatusEnum.cho_thanh_toan
                },
                id: this.payservice.ID
            });
        },
        completePayment() {
            this.$store.dispatch("payservice/setDetail", {
                data: {
                    Status: this.orderStatusEnum.hoan_thanh
                },
                id: this.payservice.ID
            });
        },
        cancelOrder() {
            this.$store.dispatch("payservice/setDetail", {
                data: {
                    Status: this.payservice.Status != this.orderStatusEnum.don_huy ? this.orderStatusEnum.don_huy : this.orderStatusEnum.cho_thanh_toan
                },
                id: this.payservice.ID
            });
        }
    },
    mounted() {
        this.refreshData();
        this.$root.$emit('REQUEST_ROOT_OPTION', {});
        this.$root.$on("REQUEST_SAVE_DATA", this.saveData);
        if (this.$route.query.uid && this.$route.query.uid !== '') {
            this.payservice.UID = Number(this.$route.query.uid);
        }
    },
    beforeDestroy() {
        this.$root.$off("REQUEST_SAVE_DATA", this.saveData);
    },
};
</script>

<style>
</style>
